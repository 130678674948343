<template>
  <qrcode-vue
    :value="value"
    :size="size"
    level="H"
  />
</template>

<script>
import QrcodeVue from 'qrcode.vue'
export default defineComponent({
  components: {
    QrcodeVue
  },
  props: {
    value: {
      type: String,
      require: true
    },
    size: {
      type: Number,
      default: 100
    }
  },
  setup (props) {
    const state = reactive({
      value: props.value,
      size: props.size
    })
    return {
      ...toRefs(state)
    }
  }
})
</script>
